/** @jsx jsx */

import { css, jsx } from '@emotion/react'

import React, { useState, useEffect } from 'react'
import { Link, graphql } from 'gatsby'

import { globalCss, variables, colors } from '../styles/global'
import Layout from '../components/layout'

const Promo = ({ data }: any) => {
  return (
    <Layout hideMenu={false}>
      <div
        className="container"
        css={css`
          background-color: ${colors.black};
          color: ${colors.white};
          padding: 30px ${variables.HPaddingDesktop}px 40px
            ${variables.HPaddingDesktop}px;
          ${variables.mobile} {
            padding: 30px ${variables.HPaddingMobile}px 0px
              ${variables.HPaddingMobile}px;
          }
          display: flex;
          flex-direction: column;
          max-width: 850px;
          margin: auto;
        `}
      >
        <h2
          css={css`
            margin: auto;
            margin-top: 60px;
            margin-bottom: 20px;
            text-align: center;
          `}
        >
          First Class Flamingos
        </h2>

        <div className="youtube-video-container" css={css``}>
          <iframe
            id="promo"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/o43CK-V1W4w?si=GceG5VNjlxLpud7Z&amp;controls=1&amp;autoplay=1&amp;modestbranding=1&amp;mute=1&amp;loop=1&amp;showinfo=0&amp;rel=0"
            title="First Class Flamingos PROMO"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"
          ></iframe>
        </div>
        <p
          css={css`
            text-align: center;
            margin-top: 20px;
            margin-bottom: 50px;
          `}
        >
          Festivaltent, bruiloft of bedrijfsfeest, deze eersteklas party-animals
          weten precies hoe ze elke party naar hogere sferen kunnen tillen! Van
          danceknallers tot stevige rock, van 90's hits tot Nederlandstalige
          meezingers, deze funky vogels draaien hun pootjes er niet voor om. Met
          hun enorme podiumskills staat dit zestal garant voor een episch feest!
        </p>
      </div>
    </Layout>
  )
}

export default Promo
